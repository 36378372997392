import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Box, Label, Input, Textarea, Button, Message, Spinner } from 'theme-ui'

export default props => (
  <Layout {...props}>
    <Seo title='Contatti' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Contattaci"
          subheader='Ti risponderemo appena possibile.'
        />
        <Divider />
        <form>
            <Box variant='forms.row'>
            <Box variant='forms.column'>
                <Label htmlFor='contact-form-name'>Nome</Label>
                <Input type='text' id='contact-form-name' name='name' required />
            </Box>
            <Box variant='forms.column'>
                <Label htmlFor='contact-form-company'>Azienda</Label>
                <Input type='text' id='contact-form-company' name='company' />
            </Box>
            </Box>
            <Box variant='forms.row'>
            <Box variant='forms.column'>
                <Label htmlFor='contact-form-email'>Email</Label>
                <Input
                type='email'
                placeholder='email@example.com'
                id='contact-form-email'
                name='email'
                required
                />
            </Box>
            <Box variant='forms.column'>
                <Label htmlFor='contact-form-phone'>Numero telefonico</Label>
                <Input
                type='tel'
                placeholder='(xxx) xxx-xxxx'
                id='contact-form-phone'
                name='phone'
                />
            </Box>
            </Box>
            <Box variant='forms.row'>
            <Label htmlFor='contact-form-subject'>Oggetto</Label>
            <Input type='text' id='contact-form-subject' name='subject' required />
            </Box>
            <Box variant='forms.row'>
            <Label htmlFor='contact-form-message'>Il tuo messaggio</Label>
            <Textarea name='message' id='contact-form-message' />
            </Box>
            <Button>
            Invia
            </Button>
        </form>
      </Main>
    </Stack>
  </Layout>
)
